.card {
	margin-top: 1em;
	height: calc(100% - 1em); // this must match the margin-top

	a {
		text-decoration: none;
	}

	&.not-connected {
		opacity: 0.5;
		.not-connected-message {
			margin-left: 10px;
		}
	}

	.stat-chart-tile {
		margin: auto;
		min-width: 80px;
		max-width: 130px;
		@media screen and (min-width: 600px) {
			min-width: 100px;
			width: 130px;
		}
	}

	.stat-content-tile {
		text-align: center;
	}

	.card-root {
		padding: 0.5em;
		height: 2em;

		.header-link {
			display: flex;
			align-items: center;
			@media screen and (max-width: 599px) {
				display: none;
			}
		}
	}

	.throughput {
		padding-bottom: 2em;
	}

	.card-header-stripe {
		height: 8px;
		&.red {
			background-color: rgba(red, 0.8);
		}
		&.orange {
			background-color: rgba(orange, 0.8);
		}
		&.green {
			background-color: rgba(green, 0.8);
		}
	}

	.card-actions {
		display: block;
	}

	.content-footer {
		padding-top: 1em;
		.uptime {
			width: 2em;
			padding-right: 0.4em;
		}
		.error-queue {
			margin-right: 1em;
		}
	}
}
