@import "./styles/colors.scss";

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: $blue;
	padding: 0rem 2rem;
	display: flex;
	flex-direction: column;
	color: $white;
	h1 {
		color: $white;
	}
}

body {
	background-color: $light-gray;
}
