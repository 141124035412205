// core
$blue: #1a81f4;
$teal: #23c5bf;
$orange: #fa7e2e;
$purple: #7340e4;

// neutral
$black: #050f19;
$gray: #7e858c;
$light-gray: #d9dfe6;
$white: #ffffff;

$primary: $blue;
$secondary: $teal;

// Table header column colour
$table-header: rgba(242, 241, 238, 0.6);