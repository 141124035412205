@import "../../styles/colors.scss";

.cp_tree-table {
	font-size: 14px;
	color: black;
	background: #fff;
	border: 1px solid $light-gray;
	height: 100%;
}

/* Header */
.cp_tree-table .cp_tree-table_header {
	color: $gray;
	background: $table-header;
	border-bottom: 1px solid $light-gray;
}

/* Cells */
.cp_tree-table .cp_tree-table_cell, .cp_tree-table .cp_tree-table_header-cell {
	display: flex;
	align-items: center;
	padding: 0 10px;
}

.cp_tree-table_viewport {
	height: calc(100% - 32px) !important;
}