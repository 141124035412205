@mixin progress-bar($color) {
	height: 10px;
	width: 100%;
	border-radius: 4px;
	background-color: lightgray;
	> * {
		background-color: $color;
	}
}
.green-progress-bar {
	@include progress-bar(green);
}
.orange-progress-bar {
	@include progress-bar(orange);
}
.red-progress-bar {
	@include progress-bar(red);
}
